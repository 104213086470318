<template>
 <!-- 客房事件页面 -->
 <!-- 头部选择器 -->
 <div class="my-box">
  <el-card shadow="always" class="my-card topS">
   <el-form label-width="80px">
    <el-row :gutter="10" style="margin-bottom: 0">
     <el-col :span="3">
      <el-form-item label="品牌">
       <el-select v-model="searchForm.brandId" @change="selectOne" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in brandSelectData" :key="index" :label="item.brandName" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label="门店">
       <el-select v-model="searchForm.hotelId" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in hotelSelectData" :key="index" :label="item.name" :value="item.id"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label="卡类型">
       <el-select v-model="searchForm.position" disabled clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in typeLists.cardTypes" :key="index" :label="item.label" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
     </el-col>

     <el-col :span="4">
      <el-form-item label="开始时间">
       <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" :picker-options="startDateDisabled" default-time="00:00:00" v-model="searchForm.startDate" type="datetime" placeholder="选择日期">
       </el-date-picker>
      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label="结束时间">
       <el-date-picker v-model="searchForm.endDate" :picker-options="endDateDisabled" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" default-time="23:59:59" placeholder="选择日期">
       </el-date-picker>
      </el-form-item>
     </el-col>

     <el-col :span="5">
      <div class="grid-content reset-button">
       <el-button size="mini" type="primary" @click="handleSearch">查询</el-button>
       <el-button size="mini" @click="reset">重置</el-button>
       <el-button size="mini" v-if="showexport" @click="exportExcel(0)" :loading="downLoading">导出</el-button>
      </div>
     </el-col>
    </el-row>
    <el-row :gutter="10" style="margin-bottom: 0">
     <el-col :span="4">
      <el-form-item label="总做房次数" label-width="100px">
       <el-input v-model="roomForm.cleanRoomCount" disabled class="leftInput"></el-input>
       <span style="margin-left:10px">次</span>
      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label="总做房率">
       <el-input v-model="roomDate.cleanRoomRate" disabled class="leftInput"></el-input>
       <span style="margin-left:10px">%</span>
      </el-form-item>
     </el-col>
     <el-col :span="5">
      <el-form-item label="总做房平均时长" label-width="120px">
       <el-input v-model="roomDate.cleanRoomLongTime" disabled class="leftInput"></el-input>
       <!-- <span style="margin-left:10px">分钟</span> -->
      </el-form-item>
     </el-col>
     <el-col :span="10">
      <el-form-item label="" label-width="10px">
       <span style="color:#999">（ 做房率=做房次数÷出租房次x100%，做房占比=个人做房数÷总做房数 ）</span>
      </el-form-item>
     </el-col>
    </el-row>
   </el-form>
  </el-card>
  <el-card>
   <el-table :data="tablist" v-loading="loading" id="tab" :height="height" style="width: 100%">
    <el-table-column v-for="(item,i) in execlTab" :key="i" :width="item.width" :prop="item.value" :label="item.label">
     <template slot-scope="scope">
      <div v-if="item.solt_TEXT">
       {{showLabelText(scope.row[item.value],typeLists[item.solt_TEXT])}}
      </div>
      <div v-else-if="item.value=='opsAverageTime'">
       {{showLongTimes(scope.row.opsAverageTime)}}
      </div>
      
       <div v-else-if="item.value=='startTime'">
       {{getDay(scope.row.startTime)}}
      </div>
      <div v-else-if="item.value=='opsPercent'">
       {{scope.row[item.value]?scope.row[item.value]*100:0}}%
      </div>
      <div v-else>
       {{scope.row[item.value]}}
      </div>
     </template>
    </el-table-column>
   </el-table>
   <div class="page">
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="prev, pager, next, sizes, total, jumper" :total="total">
    </el-pagination>
   </div>
  </el-card>
 </div>
</template>
<script>
import { export_json_to_excel } from "@/vendor/Export2Excel.js";
import {
 getLongDateTime,
 secondGetLongTime,
 showLabel,
 labelShowDate,
} from "@/api/common.js";
import {
 makeRoomList,
 getBrandSelect,
 getHotelSelect,
 makeRoomOpsRate,
} from "@/api";
const typeLists = {};
export default {
 data() {
  return {
   height: null,
   tablist: [],
   execlTab: [
    {
     label: "品牌",
     value: "brandName",
    },
    {
     label: "门店",
     value: "hotelName",
     width: 280,
    },
    {
     label: "卡类型",
     value: "position",
     solt_TEXT: "cardTypes",
    },
    {
     label: "姓名",
     value: "employeeName",
    },
    {
     label: "卡号",
     value: "cardSn",
    },
    // {
    //  label: "开始时间",
    //  value: "startTime",
    // },
    // {
    //  label: "结束时间",
    //  value: "endTime",
    // },
 {
     label: "做房时间",
     value: "startTime",
    },
    {
     label: "做房次数",
     value: "opsCount",
    },
    {
     label: "做房占比",
     value: "opsPercent",
    },
    {
     label: "做房平均时长",
     value: "opsAverageTime",
    },
   ],
   roomForm: {},
   loading: false,
   downLoading: false,
   // 查询的数据
   searchForm: {
    brandId: null,
    hotelId: null,
    position: 0,
    startDate: null,
    endDate: null,
   },
   total: 0,
   pageNum: 1,
   pageSize: 10,
   listQuery: { position: 0 },
   brandSelectData: null,
   hotelSelectData: null,
   startDateDisabled: {
    // 限制日期
    disabledDate: this.startDisabled,
   },
   endDateDisabled: {
    // 限制日期
    disabledDate: this.endDisabled,
   },
  };
 },

 beforeMount() {
  let h = document.documentElement.clientHeight || document.body.clientHeight;
  this.height = h - 295;
 },
 computed: {
  roomDate() {
   let data = {
    cleanRoomRate: 0,
    cleanRoomLongTime: 0,
   };
   if (this.roomForm) {
    data.cleanRoomRate =
     this.roomForm.cleanRoomCount && this.roomForm.checkoutCount
      ? ((this.roomForm.cleanRoomCount / this.roomForm.checkoutCount) * 100).toFixed(2)
      : 0;
      console.log(data.cleanRoomRate,this.roomForm.cleanRoomCount,this.roomForm.checkoutCount);
    let cleanRoomLongTime =
     this.roomForm.cleanRoomTimeTotal && this.roomForm.cleanRoomCount
      ? this.roomForm.cleanRoomTimeTotal / this.roomForm.cleanRoomCount
      : 0;
     data.cleanRoomLongTime= this.showLongTimes(cleanRoomLongTime)
   }
   return data;
  },
  typeLists() {
   let obj = typeLists;
   obj.cardTypes = this.$store.state.cardTypes;
   return obj;
  },
  showexport: function () {
   return this.$store.state.roleMenu.includes("makeRoom:export");
  },
 },

 created() {
  this.getList();
  this.initialize();
 },
 methods: {
  startDisabled(t) {
   if (this.searchForm.endDate && t)
    return new Date(t).getTime() > new Date(this.searchForm.endDate).getTime();
   return false;
  },
  endDisabled(t) {
   if (this.searchForm.startDate && t)
    return (
     new Date(t).getTime() < new Date(this.searchForm.startDate).getTime()
    );
   return false;
  },
  // 根据值显示信息
  showLabelText(val, list) {
   return showLabel(val, list);
  },
  
   getDay(val) {
   return labelShowDate(val);
  },
  // 时长
  showLongTimes(val) {
   return secondGetLongTime(val);
  },

  // 导出
  exportExcel(i) {
   this.downLoading = true;
   if (this.loading) {
    if (i < 5) {
     setTimeout(() => {
      this.exportExcel(i);
     }, 1000);
    }
   } else {
    if (!this.tablist || this.tablist.length == 0) {
     this.$message.warning("未加载到数据");
     this.downLoading = false;
     return;
    }
    let tHeader = [];
    let filterVal = [];
    this.execlTab.forEach((v) => {
     tHeader.push(v.label);
     filterVal.push(v.value);
    });
    let list = this.getExeclData();
    let data = this.formatJson(filterVal, list);
    setTimeout(() => {
     export_json_to_excel(tHeader, data, "做房报表");
     this.downLoading = false;
    }, 500);
   }
  },
  // d导出execl数据转换
  getExeclData() {
   let arr = new Array();
   this.tablist.forEach((v) => {
    let obj = Object.assign({}, v);
    obj.position = showLabel(v.position, this.typeLists.cardTypes);
    obj.opsAverageTime = this.showLongTimes(v.opsAverageTime);
    obj.startTime = this.getDay(v.startTime);
      obj.opsPercent = `${v.opsPercent?v.opsPercent*100:0}%`;
    arr.push(obj);
   });
   return arr;
  },
  formatJson(filterVal, jsonData) {
   return jsonData.map((v) => filterVal.map((j) => v[j]));
  },
  initialize() {
   getBrandSelect().then((res) => {
    if (res.status === 200) {
     this.brandSelectData = res.data.data;
    }
   });
  },
  getSearchData() {
   this.listQuery = {};
   this.listQuery.position = 0;
   for (let key in this.searchForm) {
    if (this.searchForm[key] || this.searchForm[key] === 0) {
     this.listQuery[key] = this.searchForm[key];
    }
   }
  },
  // 获取门店下拉框 -- 2
  selectOne(id) {
   this.searchForm.hotelId = null;
   this.hotelSelectData = null;
   getHotelSelect(id).then((res) => {
    if (res.data.data) {
     this.hotelSelectData = res.data.data;
    } else {
     this.$message({
      message: "该品牌下没有门店数据",
      type: "warning",
     });
    }
   });
  },
  // 搜索
  handleSearch() {
   this.pageNum = 1;
   this.getSearchData();
   this.getList();
  },
  // 重置
  reset() {
   this.pageNum = 1;
   this.searchForm = {
      brandId: null,
    hotelId: null,
    position: 2,
    startDate: null,
    endDate: null,
   };
   this.getSearchData();
   this.getList();
  },
  handleSizeChange(val) {
   this.pageSize = val;
   this.getList();
  },
  handleCurrentChange(val) {
   this.pageNum = val;
   this.getList();
  },
  // 列表数据
  getList() {
   this.loading = true;
   makeRoomList(this.pageNum, this.pageSize, this.listQuery)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.tablist = res.data.data.rows;
      this.total = res.data.data.total;
     } else {
      this.$message.warning(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
   let data = Object.assign({}, this.listQuery);
   delete data.position;
   makeRoomOpsRate(data).then((res) => {
    console.log(res);
    if (res.data.code == "000000") {
     this.roomForm = res.data.data;
    } else {
     this.$message.error("做房统计失败");
    }
   });
  },
 },
};
</script>
<style lang="scss" scoped >
.leftInput {
 width: 80%;
}
.my-box {
 .my-card {
  margin-bottom: 10px;
 }

 .el-row {
  margin-bottom: 10px;
  &:last-child {
   margin-bottom: 0;
  }
 }

 .grid-content {
  border-radius: 4px;
  height: 36px;
  white-space: nowrap;
  .el-input__inner {
   max-width: 190px;
  }
  span {
   font-size: 14px;
  }
 }

}
</style>


